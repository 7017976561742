<template>
  <v-snackbar v-model="showObj.show" :color="getColor" :timeout="getTimeOut" :top="true">
    <div>
      <v-icon dark>{{ getIcon }}</v-icon>
      {{ showObj.text }}
    </div>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    showObj: {
      show: { type: Boolean, required: true },
      text: { type: String, required: true },
      color: { type: String },
      timeout: { type: Number },
      icon: { type: String }
    }
  },
  computed: {
    getColor() {
      if (this.showObj.color == undefined) {
        return "#E91E63";
      } else {
        return this.showObj.color;
      }
    },
    getTimeOut() {
      if (this.showObj.timeout == undefined) {
        return 5000;
      } else {
        return this.showObj.timeout;
      }
    },
    getIcon() {
      if (this.showObj.icon == undefined) {
        return "mdi-alert-circle-outline";
      } else {
        return this.showObj.icon;
      }
    }
  }
};
</script>
